// i18next-extract-mark-ns-start payments-orchestration

//Payment methods
import {AnchorLink} from 'components/AnchorLink';
import {Background} from 'components/Background';
import {Clients} from 'components/Clients';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql, PageProps} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';
import index2 from 'images/best_payment_gateway_rates.svg';

// Clients
import index1 from 'images/payment_gateway.svg';
import index6 from 'images/payment_provider.svg';

//Images
import paymentOrchestIndex_en from 'images/paymentOrchestIndex_en.png';
import paymentOrchestIndex_es from 'images/paymentOrchestIndex_es.png';
import paymentOrchestIndex_fr from 'images/paymentOrchestIndex_fr.png';
import index3 from 'images/shopify_payment_gateway.svg';

import React from 'react';
import styled from 'styled-components';
import {ScreenSizes} from '../../../../types/responsive';
import {Language} from '../../../../locales/types';
import {InternalPageLink} from 'components/links/Pages';
import {GoogleReviews, Place} from 'components/GoogleReviews';

const IndexBackground = styled.div`
  position: relative;

  &:before {
    display: block;
    content: '';
    position: absolute;
    transform: rotate(-58deg);
    left: 70%;
    top: 0;
    height: 31.25rem;
    width: 62.5rem;
    z-index: -1;
    background: #aab4e9;
    @media (max-width: ${ScreenSizes.md}) {
      display: none;
    }
  }
`;

const IndexContent = styled.div`
  max-width: 33rem;
  @media (max-width: ${ScreenSizes.lg}) {
    max-width: 32rem;
  }
  @media (max-width: ${ScreenSizes.md}) {
    max-width: 100%;
    padding-top: 0.625rem;
  }
`;

const LargeSectionHeader = styled(SectionHeader)`
  h1 {
    font-size: 2.625rem;
    line-height: 3.25rem;
    @media (max-width: ${ScreenSizes.sm}) {
      font-size: 2rem;
      line-height: 2.75rem;
    }
  }
`;

export const IndexImage = styled.div`
  margin: 0px -70px 0 0;
  @media (max-width: ${ScreenSizes.lg}) {
    margin: 0;
  }

  @media (max-width: ${ScreenSizes.md}) {
    margin: 3.5rem 0 0 0;
    text-align: center;
    img {
      width: 65%;
    }
  }
  @media (max-width: ${ScreenSizes.xs}) {
    display: none;
  }
`;

const ReviewsBackground = styled.div`
  position: relative;
  margin-top: 100px;

  &:before {
    display: block;
    content: '';
    z-index: -1;
    height: 70%;
    position: absolute;
    transform: skew(0, -9deg);
    left: 0;
    right: 0;
    bottom: 0;
    top: 20%;
    background: linear-gradient(96.34deg, #00e9d5 0%, #aab4e9 100%);
  }
`;

const paymentOrchestImgMap: Record<Language, string> = {
  de: paymentOrchestIndex_en,
  it: paymentOrchestIndex_en,
  pt: paymentOrchestIndex_en,
  en: paymentOrchestIndex_en,
  ca: paymentOrchestIndex_es,
  es: paymentOrchestIndex_es,
  fr: paymentOrchestIndex_fr
};
const Orchestration: React.FC<PageProps> = (props) => {
  const {t, language} = useI18next();
  const paymentOrchestImg: string = paymentOrchestImgMap[language as Language];
  const {data} = props;
  const place: Place = {
    rating: data.googlePlacesPlace.rating,
    user_ratings_total: data.googlePlacesPlace.user_ratings_total,
    reviews: data.allGooglePlacesReview.nodes
  };
  return (
    <>
      <SEO
        path="payments-orchestration"
        title={t('Payments Orchestration')}
        description={t(
          'Use payments orchestration to grow revenue, boost customer experience, and manage your entire payments stack in one platform. Get started ››'
        )}
      />
      <IndexBackground>
        <Content>
          <Section centered sx={{marginTop: '1.875rem', flexDirection: {md: 'column'}}}>
            <IndexContent>
              <LargeSectionHeader underline tagName="h1">
                <Trans>Grow your revenue with payments orchestration</Trans>
              </LargeSectionHeader>
              <Trans parent="p">
                Approve more payments and increase your conversion rate with payments orchestration.
                Gain control and flexibility to route customer transactions to multiple payment
                processors — and do it all from a single dashboard. You’ll skip the headache of
                managing many payment integrations by using a system that’s built to help you
                optimize your payment flows.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </IndexContent>
            <IndexImage>
              <img src={paymentOrchestImg} alt="Grow your revenue with payments orchestration" />
            </IndexImage>
          </Section>
        </Content>
      </IndexBackground>
      <Background>
        <Content>
          <Section>
            <SectionImage
              src={index2}
              alt="Shopify payments orchestration"
              title="Shopify payments orchestration"
              width={600}
              height={350}
              mobileWidth={280}
            />
            <div>
              <SectionHeader>
                <Trans>Improve the customer experience</Trans>
              </SectionHeader>
              <Trans parent="p">
                With payments orchestration, you can set payment processing rules based on your
                business needs. Choose a default credit card payment processor, and if it’s
                experiencing downtime we’ll send the transaction to another one. Routing payments to
                the best-performing provider reduces payment failures, improves customer experience,
                and leads to more approved payments (i.e. more sales).
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Discover MONEI</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
          <Section reverseOnMobile>
            <div>
              <SectionHeader>
                <Trans>Manage your entire payments stack in one platform</Trans>
              </SectionHeader>
              <Trans parent="p">
                Consumers expect e-commerce businesses to accept a wide range of{' '}
                <InternalPageLink slug="payment-methods">online payment methods</InternalPageLink>.
                This includes credit cards, alternative forms of payment such as digital wallets,
                installment payments, and local payment options.
              </Trans>
              <Trans parent="p">
                Rather than integrating with many payment service providers, payments orchestration
                streamlines the process so you can <b>save time & money</b> managing your entire
                payment stack and transaction data in one dashboard.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Get MONEI Now</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
            <SectionImage
              src={index3}
              alt="Secure payments orchestration platform"
              title="Secure payments orchestration platform"
              width={400}
              height={428}
              mobileWidth={210}
            />
          </Section>
          <Section>
            <SectionImage
              src={index1}
              alt="PCI payments orchestration platform"
              title="PCI payments orchestration platform"
              width={600}
              height={600}
              mobileWidth={280}
            />
            <div>
              <SectionHeader>
                <Trans>Approve more payments</Trans>
              </SectionHeader>
              <Trans parent="p">
                Implementing features to improve transaction approval rates should be a top
                priority. Routing payments, accepting local payment methods, and setting payment
                method conditions such as relevance to the customer and chance of successful payment
                are all benefits of payments orchestration that can help improve your bottom line.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
          <Section reverseOnMobile>
            <div>
              <SectionHeader>
                <Trans>Ready to optimize your payment flows?</Trans>
              </SectionHeader>
              <Trans>
                Connect MONEI with all major e-commerce platforms or integrate with your
                custom-built website using the{' '}
              </Trans>
              <AnchorLink href="https://dashboard.monei.com/?action=signUp">
                <Trans>MONEI API</Trans>
              </AnchorLink>
              . <Trans>Open your account</Trans>{' '}
              <AnchorLink href="https://dashboard.monei.com/?action=signUp">
                <Trans>now</Trans>
              </AnchorLink>{' '}
              <Trans>to enjoy the benefits of payments orchestration.</Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
            <SectionImage
              src={index6}
              alt="payments orchestration platform for Developers"
              title="payments orchestration platform for Developers"
              width={392}
              height={358}
            />
          </Section>
        </Content>
      </Background>
      <ReviewsBackground>
        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
          <SectionHeader underline sx={{textAlign: 'center'}}>
            <Trans>What MONEI merchants are saying</Trans>
          </SectionHeader>
        </div>
        <GoogleReviews place={place} />
      </ReviewsBackground>
      <Content style={{marginTop: '100px'}}>
        <Section textAlign="center">
          <div>
            <SectionHeader underline tagName="h3">
              <Trans>Proudly used by more than 5000 stores</Trans>
            </SectionHeader>
            <Clients />
          </div>
        </Section>
      </Content>
    </>
  );
};

export default Orchestration;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "payments-orchestration"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    googlePlacesPlace {
      user_ratings_total
      rating
    }
    allGooglePlacesReview(sort: {fields: rating, order: DESC}, filter: {rating: {gt: 2}}) {
      nodes {
        id
        rating
        text
        relative_time_description
        profile_photo_url
        author_name
      }
    }
  }
`;
